<template>
  <div class="container mescroll-touch">
    <img class="poster" src="@/assets/images/ElectronicPhoto.jpg" />
    <img class="uploadImg" v-show="cropperImg" :src="cropperImg" alt="" srcset="">
    <van-uploader class="uploadBtn" accept="image/*" :after-read="afterRead">
      <div class="uploadPicture"></div>
    </van-uploader>
    <div class="generateImage" @click="generateImage"></div>
    <!-- <div ref="uploaderImg" class="canvasImg">
      <img :src="fileSrc" alt="">
    </div> -->
    <van-popup class="vanPopup" v-model="showCropper" :close-on-click-overlay="false" @opened="popupOpened">
      <p class="title">图片裁剪</p>
      <div class="cropper">
        <img class="cropperImage" ref="cropperImage" :src="fileSrc" alt="">
      </div>
      <div class="cropperBtn" @click="sureCropper">裁剪</div>
      <!-- <div style="width:100%;height:300px">
        <vue-cropper
          :img="fileSrc"
          ref="cropper"
          autoCrop 
          autoCropWidth="120"
          autoCropHeight="120" 
          :centerBox="true" 
          :fixed="true" 
          :fixedNumber="[1, 1]"
          :canMove="false"
          :canScale="true"/>
      </div>
      <div class="cropperBtn" @click="sureVueCropper">裁剪</div> -->
    </van-popup>
    <van-popup class="vanPopup" v-model="showGenerateImage" :close-on-click-overlay="false" @opened="generateImageOpened">
      <van-icon class="close" name="close" @click="showGenerateImage = false" />
      <span class="tips">请长按保存到手机后分享至朋友圈</span>
      <div class="generateImageBox" ref="generateImageBox" v-if="!electronicPhoto">
        <img class="generateImagePoster" src="@/assets/images/ElectronicPhoto1.jpg" />
        <img class="uploadImg1" v-show="cropperImg" :src="cropperImg" alt="" srcset="">
      </div>
      <img class="electronicPhoto" v-else :src="electronicPhoto" alt="">
    </van-popup>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
// import { VueCropper }  from 'vue-cropper' 
import html2canvas from 'html2canvas';

export default {
  name: 'ElectronicPhoto',
  data() {
    return {
      fileSrc: '',
      showCropper: false,
      cropper: null,
      cropperImg: '',
      showGenerateImage: false,
      electronicPhoto: '',
      uploaderImg: ''
    }
  },
  created() {
    this.$store.commit('changeNavbarIndex', 2);
  },
  methods: {
    afterRead(file) {
      // let formData = new FormData();
      // formData.append("file", file.file);
      // this.$axios.post('/Api/Api/Web/Files/VideoUpload', formData).then(res => {
      //   console.log(res);
      //   this.fileSrc = res.Data.Url;
      //   this.showCropper = true;
      // })
      console.log(file)
      this.fileSrc = file.content;
      this.showCropper = true;
      // html2canvas(this.$refs.uploaderImg).then((canvas) => {
      //   this.uploaderImg = canvas.toDataURL("image/png");
      //   console.log('this.uploaderImg-----', this.uploaderImg)
      //   this.showCropper = true;
      // });
    },
    popupOpened() {
      if(!this.cropper) {
        this.initCropper();
      }
      this.cropper.replace(this.fileSrc);
    },
    initCropper() {
      this.cropper = new Cropper(this.$refs.cropperImage, {
        aspectRatio: 16 / 16,
        // aspectRatio: this.autoCropWidth / this.autoCropHeight,
        viewMode: 1,
        dragMode: 'move',
        // preview: '.before', // 预览
        background: false,
        autoCropArea: 1,
        zoomOnWheel: true,
      })
    },
    sureCropper() {
      console.log(this.cropper)
      if(this.cropper) {
        this.cropperImg = this.cropper
        .getCroppedCanvas({
          // imageSmoothingQuality: 'high'
        })
        .toDataURL('image/jpeg');
      }
      // this.cropperImg = this.cropper
      //   .getCroppedCanvas({
      //     // imageSmoothingQuality: 'high'
      //   })
      //   .toDataURL('image/jpeg');
      // let blobData = this.cropper
      //   .getCroppedCanvas({
      //     imageSmoothingQuality: 'high'
      //   })
      //   .toBlob((blob) => {
      //     console.log(blob)
      //     this.cropperImg = URL.createObjectURL(blob)
      //     console.log('this.cropperImg', this.cropperImg)
      //   });
      this.showCropper = false;
      console.log('cropperImg----', this.cropperImg)
    },
    generateImage() {
      if(this.cropperImg) {
        this.electronicPhoto = '';
        this.showGenerateImage = true;
      } else {
        this.$toast.fail('请上传图片');
      }
    },
    generateImageOpened() {
      html2canvas(this.$refs.generateImageBox).then((canvas) => {
        this.electronicPhoto = canvas.toDataURL("image/png");
        let base64Img = this.electronicPhoto.substring(this.electronicPhoto.indexOf(',')+1);
        if(this.electronicPhoto) {
          this.$axios.post('/Api/Api/Web/Files/FileUpload', {
            Type: 1,
            fl: base64Img
          }).then(res => {})
        }
      });
    },
    // sureVueCropper () {
    //     //获取截图后的数据
    //   this.$refs.cropper.getCropData(data => {
    //     this.cropperImg = data;
    //     this.showCropper = false;
    //   })
    // },
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  padding-bottom: 50px;
  .canvasImg {
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 0;
    img {
      width: 100%;
    }
  }
  .poster {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
  }
  .uploadImg {
    position: absolute;
    top: 20%;
    left: 15.5%;
    width: 69%;
    height: 267px;
    z-index: 99;
  }
  .uploadBtn {
    position: absolute;
    top: 67.2%;
    left: 10%;
    width: 37%;
    height: 45px;
    z-index: 99;
  }
  .uploadBtn /deep/ .van-uploader__wrapper {
    width: 100%;
    height: 100%;
  }
  .uploadBtn /deep/ .van-uploader__input-wrapper {
    width: 100%;
    height: 100%;
  }
  .uploadPicture {
    width: 100%;
    height: 100%;
  }
  .generateImage {
    position: absolute;
    top: 67.2%;
    right: 10%;
    width: 37%;
    height: 45px;
    z-index: 99;
  }
  .vanPopup {
    width: 80%;
    border-radius: 6px;
    background-color: #FFFFFF;
    padding: 10px;
    box-sizing: border-box;
    .title {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .cropper {
      height: 200px;
      .cropperImage {
        display: block;
        max-width: 100%;
      }
    }
    .cropperBtn {
      width: 70px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background-color: #66B1FF;
      color: #FFFFFF;
      font-size: 14px;
      border-radius: 3px;
      margin: 15px auto 5px;
    }
    .close {
      position: absolute;
      top: 5px;
      right: 4px;
      font-size: 18px;
    }
    .tips {
      display: inline-block;
      width: 100%;
      font-size: 14px;
      color: #333333;
      text-align: center;
    }
    .generateImageBox {
      position: relative;
      margin-top: 6px;
      .generateImagePoster {
        display: block;
        width: 100%;
      }
      .uploadImg1 {
        position: absolute;
        // top: 18%;
        top: 17.5%;
        left: 11%;
        width: 78%;
        height: 225px;
        z-index: 99;
      }
    }
    .electronicPhoto {
      display: block;
      width: 100%;
      margin-top: 6px;
    }
  }
}
</style>